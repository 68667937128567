<script>
import {useStore} from "vuex";
import { useRouter} from "vue-router";
import {ref} from "vue";
import countries from "@/assets/countries.csv";

export default {
  setup(){
    const store    = useStore();
    const router   = useRouter();

    const email    = ref("");
    const password = ref("");
    const country  = ref(countries[0].id);
    const type     = ref("0");

    const showSuccess = ref(null);
    const showError   = ref(null);

    const makeObject = () => {
      return {
        email    : email.value,
        password : password.value,
        country  : country.value,
        type     : type.value
      }
    }

    const saveUser = () => {
      const obj = makeObject();
      store.dispatch("saveUser", obj)
      .then( () => {
        router.push({
          name : "UsersAdmin"
        });
        // showSuccess.value = true;
        // setTimeout(() => showSuccess.value = null, 5000)
      })
      .catch( e => {
        console.log(e);
        showError.value = true;
        setTimeout(() => showError.value = null, 5000)
      });
    }

    return {
      countries,
      email,
      password,
      country,
      type,

      saveUser,
      showSuccess,
      showError
    }
  }
}
</script>

<template>
  <div>
  <h3>Agregar usuario</h3>
  <div class="ip_separator bottom"></div>
  <form @submit.prevent="saveUser" class="mt-4">
    <transition name="fade">
      <p v-if="showSuccess" class="ob_alert success">el usuario se ha actualizado</p>
    </transition>

    <transition name="fade">
      <p v-if="showError" class="ob_alert danger">el usuario no se actualizó :( </p>
    </transition>
    <div class="row">
      <div class="col-sm-6">
        <p>
      <label>correo:</label>
      <input type="email" placeholder="correo" required v-model="email" />
    </p>
      </div>
      <div class="col-sm-6">
      <p>
      <label>contraseña:</label>
      <input type="password" placeholder="contraseña" required minlength="8" maxlength="32" v-model="password" />
    </p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
      <p>
    <label>país:</label>
      <select v-model="country">
        <option v-for="country of countries" :key="`opt-${country.id}`" :value="country.id">
          {{country.name}}
        </option>
      </select>
    </p>
      </div>
      <div class="col-sm-6">
      <p>
    <label>tipo:</label>
      <select v-model="type">
        <option value="0">usuario</option>
        <option value="1">administrador</option>
      </select>
    </p>
      </div>
    </div>
    
    <div class="ip_separator bottom"></div>
    
    
    <p>
      <input type="submit" value="Guardar" />
    </p>
  </form>

  <table>
    <thead>
      <tr>
        <th>correo</th>
        <th></th>
      </tr>
    </thead>
    <tbody>

    </tbody>
  </table>
  </div>
</template>